/* eslint-disable no-unused-vars */
import { MediaProps } from '../../media/domain/media.entity';
import { UserProps } from '../../user/domain/user.entity';

export enum ESubscriptionType {
  DEMO = 'demo',
  START = 'start',
  PRO = 'pro',
  BUSINESS = 'business',
}

export enum EUpdateCreditsType {
  CREATED = 'created',
  TRANSFORMED = 'transformed',
  FOLLOW_UP = 'follow-up',
}

export type CreditConsumedDateProps = {
  type: EUpdateCreditsType;
  date: string;
};
export type CreditConsumptionHistoryProps = {
  projectId: number;
  projectName: string;
  createdAt: string;
  transformationDate: string | null;
  anniversary: number;
  amount: number;
  creditConsumedDate: CreditConsumedDateProps[];
};

export type WorkspaceProps = {
  name: string;
  users: UserProps[] | undefined;
  stripeCustomerId: string;
  logo?: MediaProps;
  numberOfIntervention: number;
  numberOfProject: number;
  subscriptionType: ESubscriptionType;
  startSubscriptionDate: string;
  endSubscriptionDate: string;
  isAutoPayment: boolean;
  creditBalance: number;
  expirationDate: string;
  creditConsumptionHistory: CreditConsumptionHistoryProps[];
  intervalPayment: string | null;
};
